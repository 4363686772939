import React from "react";
import styled from "styled-components";
import { navigate, Link } from "gatsby";

const Card = styled.button`
  background-color: ${({ color }) => (color ? color : `#000000`)};
  border: none;
  width: 400px;
  height: 300px;
  box-shadow: 1px 1px 1px;
  margin: 20px;
  align-self: center;
  border-radius: 10px;
  justify-self: center;

  :hover {
    background-color: ${({ highlightColor }) =>
      highlightColor ? highlightColor : `#000000`};
    cursor: pointer;
  }

  @media only screen and (max-width: 820px) {
    width: calc(200px + 20vw);
    height: calc(100px + 20vw);
  }
`;

const CardText = styled.h1`
  color: white;
  font-size: calc(20px + 1.9vw);

  @media only screen and (min-width: 1400px) {
    font-size: 46px;
  }
`;

const CardEmoji = styled.div`
  font-size: 60px;
`;

const CardButton = ({ text, color, highlightColor, emoji, to }) => {
  return (
    <Card
      onClick={() => {
        navigate(`${to}`);
      }}
      color={color}
      highlightColor={highlightColor}
    >
      <CardEmoji>{emoji}</CardEmoji>
      <CardText>{text}</CardText>
    </Card>
  );
};

export default CardButton;
