import * as React from "react";
import "../styles/index.css";
import drawing from "../images/home/undraw_medicine_b1ol.svg"
import Navbar from "../components/Navbar";
import Landing from "../containers/Landing";
import ButtonGrid from "../containers/ButtonGrids/Landing";
import styled from 'styled-components'
import Footer from "../components/Footer/old";
import SEO from "../components/seo";
import Advert from "../components/Advert";
import { FirebaseContext } from "../components/Firebase";
import Header from "../containers/Home/Header";
import Usps from "../containers/Home/Usps";
import ReadyToGo from "../containers/Home/ReadyToGo";
import Specialities from "../containers/Home/Specialities";
import Timer from "../containers/Home/Timer";
import KeepScore from "../containers/Home/KeepScore";
import MarkScheme from "../containers/Home/MarkScheme";
import Pricing from "../containers/Home/Pricing";
import AdditionalResources from "../containers/Home/AdditionalResources";
import TrackProgress from "../containers/Home/TrackProgress";
import HighQualityFeedback from "../containers/Home/HighQualityFeedback";
import usePremiumStatus from "../components/Stripe/usePremiumStatus";

const CloudWrapper = styled.img`
  margin-top: 60vh;
  position: absolute;
  z-index: -1;
  height: 150vh;
`

// markup
const IndexPage = () => {
  const {user, firebase, loading} = React.useContext(FirebaseContext);
  return (
    <FirebaseContext.Provider value={{user, firebase, loading}}>
      <SEO title="OSCEhub" description="OSCE stations for medical students. Practise with over 50 interactive cases on OSCEhub and smash your finals!"/>
      <Header/>
      <ReadyToGo/>
      <MarkScheme/>
      <AdditionalResources/>
      <KeepScore/>
      <TrackProgress/>
      <HighQualityFeedback/>
      <Pricing/>
    </FirebaseContext.Provider>
  );
};

export default IndexPage;
