import React from "react";
import Score from "images/webflow/Score.png"

function KeepScore() {
  return (
    <div class="homepage-marketing-section-2 wf-section">
      <div class="homepage-marketing-container-2 w-container">
        <div class="marketing-columns w-row">
          <div class="w-col w-col-6">
            <div class="marketing-text-div">
              <h2 class="heading">Identify your strengths & weaknesses</h2>
              <div class="subheading-text">
              Use our interactive mark scheme to get an your score with a breakdown. Identify your weak points and use it to inform your revision.
              <br/>
              <br/>
              Start practicing now.
              </div>
            </div>
          </div>
          <div class="column-7 w-col w-col-6">
            <img
              src={Score}
              loading="lazy"
              sizes="100vw"
              alt=""
              class="score-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default KeepScore;
