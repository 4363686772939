import styled from 'styled-components'
import React from 'react'

export const FooterWrapper = styled.div `
  position: relative;
  display: flex;
  background-color: black;
  height: 200px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const FooterCopyrightWrapper = styled.div `
  color: white;
  margin: 10px;
  text-align: center;
  width: 70vw;
`