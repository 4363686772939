import React from "react";
import styled from "styled-components";
import CardButton from "../../components/Buttons/CardButton";

export const CardGrid = styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin-top: 60px;
  row-gap: 100px;
  position: relative;

  @media only screen and (max-width: 2080px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 100px;

  }
  @media only screen and (max-width: 1660px) {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 50px;


  }

  @media only screen and (max-width: 1240px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 40px;


  }

  @media only screen and (max-width: 920px) {
    row-gap: 30px;
    grid-template-columns: 1fr;
  }
`

const ButtonGrid = () => {
  return (
    <CardGrid id="buttonGrid">
        <CardButton text="Medicine" color="#7f3f94" highlightColor="#834599" emoji="🩺" to='/medicine'/>
        <CardButton text="Surgery" color="#3f3f94" highlightColor="#434599" emoji="💉" to='/surgery'/>
        <CardButton text="Specialties" color="#7f9f94" highlightColor="#83a299" emoji="👁" to='/specialties'/>
        {/* <CardButton text="Emergencies" color="#7f3f34" highlightColor="#834549" emoji="🚑"/> */}
        <CardButton text="Ethics & Law" color="#7f6f24" highlightColor="#837539" emoji="👩‍⚖️" to='/ethics-and-law'/>
        <CardButton text="Miscellaneous" color="#2f2f24" highlightColor="#333539" emoji="🗣" to='/miscellaneous'/>
    </CardGrid>
  );
};

export default ButtonGrid;
