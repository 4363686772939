import React from "react";
import styled from "styled-components";
import drawing from "../images/home/undraw_medicine_b1ol.svg";
import { Link as LinkS } from "react-scroll";
import ButtonGrid from "./ButtonGrids/Landing";
// import BackgroundSlider from "gatsby-image-background-slider";
import { useStaticQuery, graphql } from "gatsby";

const StyledButton = styled(LinkS) `
  text-decoration: none;
  color: white;
  background-color: #5c73d0;
  border-radius: 30px;
  padding: 10px;

  &:hover {
    color: white;
    background-color: #6c83e0;
    cursor: pointer;
  }
`

const LandingContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  /* background: linear-gradient(-45deg, #fe8762, #f74c8e, #33b6e5, #33e5bb);
  background-size: 400% 400%;
  animation: gradient 100s ease infinite; */
  height: 100vh;
  min-height: calc(550px - 10vw);

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const LandingTextWrapper = styled.div`
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: calc(30px + 70vw);
  background-color: rgba(1, 1, 1, 0.7);
  border-radius: 30px;
  height: calc(350px - 7vw);

  @media screen and (min-width: 1200px) {
    height: 300px;
  }
`;

const Slogan = styled.h1`
  /* font-size: calc(20px + 2.5vw); */
  text-align: center;
  font-weight: bold;
  color: white;
  margin: 20px;
`;

const SmallSlogan = styled.p`
  /* font-size: calc(17px + 1vw); */
  text-align: center;
  color: #ddd;
  margin-bottom: 5px;
`;

const Landing = () => {
  return (
    <LandingContainer>
      {/* <BackgroundSlider
        query={useStaticQuery(graphql`
          query {
            backgrounds: allFile(
              filter: { sourceInstanceName: { eq: "backgrounds" } }
            ) {
              nodes {
                relativePath
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        `)}
        style={{ height: "100vh" }}
        initDelay={2} // delay before the first transition (if left at 0, the first image will be skipped initially)
        transition={6} // transition duration between images
        duration={8} // how long an image is shown
      /> */}
      <LandingTextWrapper>
        <Slogan>OSCE prep made simple</Slogan>
        <SmallSlogan>The secret to excellence is practising lots of cases</SmallSlogan>
        <StyledButton
          to="buttonGrid"
          smooth={true}
          offset={-50}
        >
          Get Started
        </StyledButton>
      </LandingTextWrapper>
    </LandingContainer>
  );
};

export default Landing;
