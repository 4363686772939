import React from "react";
import Instructions from "images/webflow/Instructions.png";
import {navigate} from 'gatsby'

function ReadyToGo() {
  return (
    <div class="homepage-marketing-section wf-section">
      <div class="homepage-marketing-container w-container">
        <div class="marketing-columns w-row">
          <div class="w-col w-col-6">
            <div class="marketing-image-div">
              <img src={Instructions} loading="lazy" sizes="100vw" alt="" />
            </div>
          </div>
          <div class="w-col w-col-6">
            <div class="marketing-text-div">
              <h2 class="heading">Get OSCE practicing, immediately.</h2>
              <div class="subheading-text">
                Ready-to-go OSCE stations with candidate and actor instructions
                + an interactive mark scheme.
                <br />
                <br />
                Start practicing now.
                <br />
                <a
                  onClick={() => navigate("/example")}
                  class="button w-button"
                >
                  Example stations
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReadyToGo;
