import React from 'react'
import {FooterCopyrightWrapper, FooterWrapper} from './FooterElements'


const Footer = () => {
  return (
    <FooterWrapper>
      <FooterCopyrightWrapper>
        All stations are reviewed by doctors and clinical teaching fellows
      </FooterCopyrightWrapper>
      <FooterCopyrightWrapper>
        OSCEhub © 2021 All rights reserved.
      </FooterCopyrightWrapper>
    </FooterWrapper>
  )
}

export default Footer