import React from "react";
import SmartMarkScheme from "images/webflow/SmartMarkScheme.png"

function MarkScheme() {
  return (
    <div id="features" class="homepage-marketing-section-2 wf-section">
      <div class="homepage-marketing-container-2 w-container">
        <div class="marketing-columns w-row">
          <div class="w-col w-col-6">
            <div class="marketing-text-div">
              <h2  class="heading">Accurate mark schemes</h2>
              <div class="subheading-text">
              Our Smart Mark Schemes uses a global scoring system (using thresholds) rather than individual scores to reflect how universities mark you in the real exams. <br></br><br></br>This ensures your final score on OSCEhub is reflective of your true performance.
              </div>
            </div>
          </div>
          <div class="column-7 w-col w-col-6">
            <img
              src={SmartMarkScheme}
              loading="lazy"
              sizes="100vw"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarkScheme;
