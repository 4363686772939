import React from "react";
import GIF from "images/webflow/GIF.gif"

function HighQualityFeedback() {
  return (
    <div class="homepage-marketing-section-2 wf-section">
      <div class="homepage-marketing-container-2 w-container">
        <div class="marketing-columns w-row">
          <div class="w-col w-col-6">
            <div class="marketing-text-div">
              <h2 class="heading">High quality feedback + GIFs</h2>
              <div class="subheading-text">
              Our feedback consists of key points that can help make or break a station as well as helpful practical OSCE tips. <br></br><br></br>Our GIFs are the perfect way to show you practical skill steps clearly and easily.
              </div>
            </div>
          </div>
          <div class="column-7 w-col w-col-6">
            <img
              src={GIF}
              loading="lazy"
              sizes="100vw"
              height="80%"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HighQualityFeedback;
