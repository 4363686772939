import React from "react";
import timer from "../../images/webflow/Timer.png"

function Timer() {
  return (
    <div class="homepage-marketing-section wf-section">
      <div class="homepage-marketing-container w-container">
        <div class="marketing-columns w-row">
          <div class="w-col w-col-6">
            <div class="marketing-image-div">
              <img
                src={timer}
                loading="lazy"
                alt=""
              />
            </div>
          </div>
          <div class="w-col w-col-6">
            <div class="marketing-text-div">
              <h2 class="heading">Adapt to OSCE timings.</h2>
              <div class="subheading-text">
              Stick to tight OSCE timing with our integrated timer. Whether you practise in-person or over Zoom, we make OSCE practise simple for you.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Timer;
