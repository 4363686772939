import React from "react";
import Glow from "../../images/webflow/Glow.png"

function TrackProgress() {
  return (
    <div class="homepage-marketing-section wf-section">
      <div class="homepage-marketing-container w-container">
        <div class="marketing-columns w-row">
          <div class="w-col w-col-6">
            <div class="marketing-image-div">
              <img
                src={Glow}
                loading="lazy"
                alt=""
              />
            </div>
          </div>
          <div class="w-col w-col-6">
            <div class="marketing-text-div">
              <h2 class="heading">Constant improvement</h2>
              <div class="subheading-text">
              Log and submit your scores to your peers to track your progress.<br></br><br></br> Easily identify your weaknesses via low-scoring stations and repeat those stations.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrackProgress;
