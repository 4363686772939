import { navigate } from "gatsby";
import React, { useContext } from "react";
import { FirebaseContext } from "../../components/Firebase";
import Image from "../../images/webflow/Tiles-large6.png"

function Header() {
  const { user } = useContext(FirebaseContext);
  //console.log(user)
  return (
    <div class="homepage-header wf-section">
      <div class="homepage-header-container w-container">
        <div class="columns w-row">
          <div class="w-col w-col-6">
            <h1 class="homepage-h1">
              OSCE Practice <br />
              For <u>1st Decile</u> Students
            </h1>
            <h2 class="homepage-h2">
              <u>Before OSCEhub:</u> Hectic OSCE practise, stations aren't challenging, and no idea if you're improving. 
              <br></br>
              <br></br>
              <u>After OSCEhub:</u> Always ready to practise, productive & challenging sessions, clear on where to improve.
            </h2>
            {user ? (
              <a onClick={() => navigate("/dashboard")} class="button w-button">
                Start OSCE Practise
              </a>
            ) : (
              <>
                <a
                  onClick={() => navigate("/browse")}
                  class="button w-button"
                >
                  Browse OSCEs
                </a>
                <a
                  onClick={() => navigate("/example")}
                  class="button-invert-colour w-button"
                >
                  Examples
                </a>
              </>
            )}
              <div class="waiting-list">1000+ medical students practicing with OSCEhub</div>

          </div>
          <div class="column w-col w-col-6">
          <a href="/allStations">
            <img src={Image}/>
            <div class="text-block">Stations reviewed by doctors 🌟</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
